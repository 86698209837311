import React from 'react';
import { graphql, navigate, withPrefix } from 'gatsby';
import { getUserLangKey } from 'ptz-i18n';

class RedirectIndex extends React.PureComponent {
    constructor(args) {
        super(args);

        if (typeof window !== 'undefined') {
            const { langs, defaultLangKey } = args.data.site.siteMetadata.languages;
            const langKey = getUserLangKey(langs, defaultLangKey);
            console.log(getUserLangKey(langs, defaultLangKey));
            const homeUrl = withPrefix(`/${langKey}/`);
            navigate(homeUrl);
        }
    }

    render() {
        return <div />;
    }
}

export default RedirectIndex;

export const pageQuery = graphql`
    query IndexQuery {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
    }
`;
